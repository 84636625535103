import React from 'react';

const Data = [
    {
        image: "https://cdn-bdlah.nitrocdn.com/CLwutxSdEKuYrkGQRECwfWPIMDKSxKKQ/assets/static/optimized/rev-96376c3/wp-content/uploads/2019/04/toyota-logo-v2-e1591371407753.png"
    },
    {
        image: "https://cdn-bdlah.nitrocdn.com/CLwutxSdEKuYrkGQRECwfWPIMDKSxKKQ/assets/static/optimized/rev-96376c3/wp-content/uploads/2019/04/Casino-Woodbine-e1591371437410.png"
    },
    {
        image: "https://cdn-bdlah.nitrocdn.com/CLwutxSdEKuYrkGQRECwfWPIMDKSxKKQ/assets/static/optimized/rev-96376c3/wp-content/uploads/2018/09/bnh-1-2-e1591371467125.png"
    },
    {
        image: "https://cdn-bdlah.nitrocdn.com/CLwutxSdEKuYrkGQRECwfWPIMDKSxKKQ/assets/static/optimized/rev-96376c3/wp-content/uploads/2018/09/seo-uoft-e1591371497432.png"
    },
    {
        image: "https://cdn-bdlah.nitrocdn.com/CLwutxSdEKuYrkGQRECwfWPIMDKSxKKQ/assets/static/optimized/rev-96376c3/wp-content/uploads/2019/04/wu-tang-clan-logo-e1591371528153.png"
    },
    {
        image: "https://cdn-bdlah.nitrocdn.com/CLwutxSdEKuYrkGQRECwfWPIMDKSxKKQ/assets/static/optimized/rev-96376c3/wp-content/uploads/2018/09/seo-sick-kids-1-e1591371558367.png"
    },
    {
        image: "https://cdn-bdlah.nitrocdn.com/CLwutxSdEKuYrkGQRECwfWPIMDKSxKKQ/assets/static/optimized/rev-96376c3/wp-content/uploads/2018/09/bnh-1-6-e1591371587748.png"
    },
    {
        image: "https://cdn-bdlah.nitrocdn.com/CLwutxSdEKuYrkGQRECwfWPIMDKSxKKQ/assets/static/optimized/rev-96376c3/wp-content/uploads/2018/09/seo-tacc-developments-e1591371633920.png"
    }
]


const BrandItem = () => {
    return (
        <>
            {Data.map((data, index) => (
                <div className="col-lg-3 col-6" key={index}>
                    <div className="brand-grid">
                        <img src={process.env.PUBLIC_URL + data.image} alt="Brand" />
                    </div>
                </div>
            ))}
        </>
    )
}

export default BrandItem;