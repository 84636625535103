import React, { useEffect, useState } from "react";
import { useForm, ValidationError } from "@formspree/react";

const FormTwo = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: ""
  });
  const [state, handleSubmit] = useForm("mdoqgbnv");
  useEffect(
    () => {
      if (state.succeeded) {
        alert("Subscribed to our newsletter successfully");
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          message: ""
        });
      }
    },
    [state.succeeded]
  );

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  return (
    <form onSubmit={handleSubmit} className="axil-contact-form">
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          className="form-control"
          name="name"
          required
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          name="email"
          required
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Phone</label>
        <input
          type="tel"
          className="form-control"
          name="phoneNumber"
          required
          onChange={handleChange}
        />
      </div>
      <div className="form-group mb--40">
        <label>How can we help you?</label>
        <textarea
          className="form-control"
          name="message"
          rows="4"
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
        >
          Get Pricing Now
        </button>
      </div>
      <ValidationError prefix="Message" field="message" errors={state.errors} />
    </form>
  );
};

export default FormTwo;
