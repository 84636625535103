import React from 'react';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import AboutFour from '../component/about/AboutFour';
import FooterTwo from '../common/footer/FooterTwo';
import BrandOne from '../component/brand/BrandOne';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import AboutTwo from '../component/about/AboutTwo';


const AboutUs = () => {

    return (
        <>
        <SEO title="About us" />
       
            <main className="main-wrapper">
            <HeaderOne />
                <BcrumbBannerOne 
                title="About Us"
                paragraph ="We strive to create a better future through cutting-edge technology solutions that enhance and simplify the way people live, work, and play. Founded in 2020 by a team of industry veterans, our mission is to transform the world by harnessing the power of innovation and pushing the boundaries of what's possible."
                styleClass="thumbnail-4"
                mainThumb="/images/banner/banner-thumb-3.png"
                />
                <AboutTwo/>
                <AboutFour />
                {/* <AboutThree />
                <AboutFive />
                <ProcessOne /> */}
                {/* <CtaLayoutOne /> */}
                {/* <BrandOne /> */}
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;