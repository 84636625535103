import React from 'react';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import SEO from '../common/SEO';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServicePropOne from '../component/service/ServicePropOne';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';


const ServiceOne = () => {

    // const designData = allData.filter(data => slugify(data.cate ? data.cate : "") === "design");



    return (
        <>
        <SEO title="Service One" />       
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
                title="Best solutions for your business"
                paragraph ="Team up with us to take your business or business idea to the next level. We create anything, Logo, Branding, Mobil App Technology, Software, literally anything."
                styleClass=""
                mainThumb="/images/services.png"
            />
            <div className="service-scroll-navigation-area">

                <div className="section section-padding" id="section1">
                    <div className="container">
                        <SectionTitle 
                            subtitle="Best Solution"
                            title="Our Service"
                            description=""
                            textAlignment="heading-left"
                            textColor=""
                        
                        />
                        <div className="row justify-content-center">
                        <ServicePropOne colSize="col-lg-6" serviceStyle="service-style-2" itemShow="6" marginTop="yes"/>
                        </div>
                    </div>
                </div>

            </div>
            {/* <CtaLayoutOne /> */}
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ServiceOne;