import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';


const AboutFour = () => {
    return (
        <div className="section section-padding case-study-featured-area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                            <img src={process.env.PUBLIC_URL + "/images/others/case-study-4.png"} alt="travel" />
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                            <div className="section-heading heading-left">
                                <span className="subtitle">Our</span>
                                <h2 className="title">Expertise</h2>
                                <p>Our team of experts specializes in a wide range of technology fields, including artificial intelligence, machine learning, robotics, IoT, cybersecurity, and software development. Our extensive knowledge and experience enable us to tackle the most complex challenges and provide our clients with innovative, reliable, and effective solutions.</p>
                            </div>
                            {/* <div className="case-study-counterup">
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="10" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Years on the market</span>
                            </div>
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="1500" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Projects delivered so far</span>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                            <div className="section-heading heading-left">
                                <span className="subtitle">Our</span>
                                <h2 className="title">Solutions</h2>
                                <p>At Pristine Software Development, we offer a diverse portfolio of products and services designed to meet the unique needs of our clients across various industries. Our solutions include:</p>
                                <ul>
                                    <li>Custom software development</li>
                                    <li>AI and machine learning applications</li>
                                    <li>Robotics and automation systems</li>
                                    <li>IoT and smart home solutions</li>
                                    <li>Cybersecurity and data protection</li>
                                    <li>Cloud computing and infrastructure</li>
                                    <li>Technical consulting and support services</li>
                                </ul>
                            </div>
                            {/* <div className="case-study-counterup">
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="10" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Years on the market</span>
                            </div>
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="1500" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Projects delivered so far</span>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                            <img src={process.env.PUBLIC_URL + "/images/others/case-study-5.jpg"} alt="travel" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutFour;