import React from 'react';
import FooterTwo from '../common/footer/FooterTwo';
import SEO from '../common/SEO';
import BannerThree from '../component/banner/BannerThree';
import BrandOne from '../component/brand/BrandOne';
import CounterUpOne from '../component/counterup/CounterUpOne';
import ProjectOne from '../component/project/ProjectOne';
import ServicePropOne from '../component/service/ServicePropOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';


const CreativeAgency = () => {

    return (
        <>
        <SEO title="Creative Agency"/>
       
        <main className="main-wrapper">
            <HeaderOne />
            <BannerThree />
            <div className="section section-padding">
                <div className="container">
                <SectionTitle 
                    subtitle="What We Can Do For You"
                    title="Services we can <br> help you with"
                    description=""
                    textAlignment="heading-left mb--20 mb_md--70"
                    textColor=""
                />
                    <div className="row">
                        <ServicePropOne colSize="col-lg-6" serviceStyle="service-style-2" itemShow="6" marginTop="yes"/>
                    </div>
                </div>
                <ul className="shape-group-7 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
                </ul>

            </div>
            <CounterUpOne />
            <ProjectOne parentClass="bg-color-light"/>
          
            {/* <TestimonialOne /> */}
            {/* <BrandOne /> */}
            {/* <BlogOne /> */}
            {/* <CtaLayoutOne /> */}
            <FooterOne />
        </main>
        </>
    )
}

export default CreativeAgency;

