import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe } from 'react-icons/fa';


const AboutTwo = () => {
    return (
        <div className="section-padding">
            <div className="container">
            <div className="row">
                    <div className="col-lg-12">
                        <div className="why-choose-us">
                            <div className="section-heading heading-left">
                                <span className="subtitle">Our </span>
                                <h3 className="title">Core Values</h3>
                            </div>
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header> Innovation</Accordion.Header>
                                    <Accordion.Body>
                                    We believe that the key to success is continuous innovation. Our team is relentlessly focused on developing new ideas, technologies, and solutions that address the challenges faced by our clients and the wider world.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header> Excellence</Accordion.Header>
                                    <Accordion.Body>
                                    We are committed to delivering the highest quality products and services to our customers. Our team of skilled engineers, designers, and developers consistently work to exceed customer expectations and set new standards in our industry.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Integrity</Accordion.Header>
                                    <Accordion.Body>
                                    At the heart of our company is a commitment to honesty, transparency, and ethical conduct. We hold ourselves accountable for our actions and strive to build trust with our clients, partners, and employees.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Collaboration</Accordion.Header>
                                    <Accordion.Body>
                                    We understand the power of teamwork and foster a culture of collaboration, both internally and with our clients. By working together, we can achieve more and deliver solutions that have a lasting impact.

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Sustainability</Accordion.Header>
                                    <Accordion.Body>
                                    We recognize the importance of protecting our planet for future generations. We are dedicated to developing and implementing sustainable practices across our business operations, as well as creating eco-friendly products and solutions.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;